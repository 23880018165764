import styled from "styled-components"

const InnerContainer = styled.div`
  padding: 1rem;
  width: 100%;
  flex: ${props => (props.half ? "1" : "auto")};
  @media screen and (max-width: 768px) {
    text-align: ${props => (props.centerText ? "center" : "left")};
  }
`
export const HeroInnerContainer = styled(InnerContainer)`
  padding: 0;
`

export default InnerContainer
