import React from "react"
import Container from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Section from "../../styles/styled-component/Section"
import { useState } from "react"
import {
  FooterItem,
  FooterLink,
  FooterList,
  Footerh3,
  FooterA,
  FooterFormDiv,
  FooterButton,
  FooterInput,
  FeedbackText,
} from "./footerElements"

import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import LangSwitchFooter from "./LangSwitchFooter"

const Footer = () => {
  const { t } = useTranslation()
  const [feedbackText, setFeedbackText] = useState("")

  return (
    <footer>
      <Section minHeight bgColor="#1a5090">
        <Container>
          <LangSwitchFooter />
        </Container>
        <Container flex>
          <InnerContainer>
            <Footerh3>Movilift</Footerh3>
            <FooterList>
              <FooterItem>
                <FeedbackText>
                  Strada Napoli 350, Castellammare di Stabia 80053, Napoli
                </FeedbackText>
              </FooterItem>
              <FooterItem>
                <FooterA href="mailto:marketing@movilift.com">
                  marketing@movilift.com
                </FooterA>
              </FooterItem>
              <FooterItem direction>
                <FooterA href="tel:+39 081 871 36 46">
                  +39 081 871 36 46
                </FooterA>
              </FooterItem>
            </FooterList>{" "}
          </InnerContainer>{" "}
          <InnerContainer>
            <Footerh3>Links</Footerh3>
            <FooterList>
              <FooterItem>
                <FooterLink to="/">
                  <Trans>Home</Trans>
                </FooterLink>
              </FooterItem>
              <FooterItem>
                <FooterLink to="/specifications">
                  <Trans>Specifications</Trans>
                </FooterLink>
              </FooterItem>
              <FooterItem>
                <FooterLink to="/design">
                  <Trans>Design</Trans>
                </FooterLink>
              </FooterItem>
              <FooterItem>
                <FooterLink to="/luxury-design">
                  <Trans>Luxury</Trans>
                </FooterLink>
              </FooterItem>
              <FooterItem>
                <FooterLink to="/gallery">
                  <Trans>Gallery</Trans>
                </FooterLink>
              </FooterItem>
              <FooterItem>
                <FooterLink to="/contact">
                  <Trans>Contact</Trans>
                </FooterLink>
              </FooterItem>
            </FooterList>
          </InnerContainer>
          <InnerContainer>
            <Footerh3>
              <Trans>Stay in touch</Trans>
            </Footerh3>
            <form
              name="divas newsletter"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
            >
              <FooterFormDiv>
                <input
                  type="hidden"
                  name="form-name"
                  value="divas newsletter"
                />
                <p style={{ display: "none" }}>
                  <label>
                    Don’t fill this out if you expect to hear from us!
                    <input name="bot-field" value="" readOnly />
                  </label>
                </p>
                <FooterInput
                  name="email"
                  type="email"
                  required
                  placeholder={t("Your email")}
                ></FooterInput>
                <FooterButton value="send" type="submit">
                  <Trans>Subscribe</Trans>
                </FooterButton>
                {
                  <div>
                    {" "}
                    <FeedbackText>{feedbackText} </FeedbackText>
                  </div>
                }
              </FooterFormDiv>
            </form>
          </InnerContainer>
        </Container>
      </Section>
    </footer>
  )
}

export default Footer
