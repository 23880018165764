import styled from "styled-components"

const Container = styled.div`
  max-width: ${props => (props.smallMaxWidth ? "660px" : "1080px")};

  height: 100%;
  width: 100%;
  margin: 0 auto;
  border-radius: 24px;
  box-shadow: ${props =>
    props.shadow ? "34px 34px 75px -24px rgb(56 50 124 / 8%)" : "none"};
  background-color: ${props => props.bgColor};
  margin-bottom: ${props => (props.spaceBottom ? "3rem" : "none")};
  align-items: ${props => (props.center ? "center" : "normal")};
  display: ${props => (props.flex ? "flex" : "block")};
  flex-direction: ${props => props.direction || "row"};
  @media screen and (max-width: 768px) {
    flex-direction: ${props =>
      props.direction === "row" ? "column-reverse" : "column"};
  }
`
export const EndPageContainer = styled(Container)`
  padding: 1rem;
  text-align: center;
  color: ${props => (props.white ? "#fff" : "inherit")};
`
export default Container
