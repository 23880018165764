import styled from "styled-components"
import { Link } from "gatsby"
export const FooterList = styled.ul`
  list-style: none;
  margin: 0 auto;
`
export const FooterItem = styled.li`
  font-weight: 500;
  direction: ${props => (props.direction ? "ltr" : "inherit")};
  color: #fff;
`

export const FooterLink = styled(Link)`
  text-decoration: none;

  font-weight: 500;
  color: #fff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const FooterA = styled.a`
  text-decoration: none;

  font-weight: 500;

  color: #fff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
export const Footerh3 = styled.h3`
  color: #fff;
`

export const FooterFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
`
export const FooterButton = styled.button`
  width: 100%;
  border-radius: 8px;
  padding: 8px 14px;
  cursor: pointer;
  margin-top: 10px;
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: 2px 2px 3px 0 rgb(140 135 166 / 12%),
    3px 7px 12px 0 rgb(140 135 166 / 8%), 1px 14px 24px 0 rgb(140 135 166 / 8%);
  color: #fff;
  background-color: #25a18e;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: #218676;
  }
`
export const FooterInput = styled.input`
  border-radius: 8px;
  border: none;
  padding: 10px;
`

export const FeedbackText = styled.p`
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
`
