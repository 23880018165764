import React from "react"
import { useState, useEffect } from "react"
import {
  MobileIcon,
  Nav,
  NavBarContainer,
  NavLink,
  NavLogo,
  NavMenu,
  NavItem,
} from "./NavbarElements"
import { FaBars } from "react-icons/fa"
import { Trans, Link as LinkLan, useI18next } from "gatsby-plugin-react-i18next"
import "./navlink.css"
import LangSwitch from "./LangSwitch"
const NavBar = ({ toggle }) => {
  const [direction, setDirection] = useState(false)

  const { language } = useI18next()
  useEffect(() => {
    const changeDirection = () => {
      if (language == "ar") {
        setDirection(!direction)
      } else {
        setDirection(direction)
      }
    }
    changeDirection()
  }, [])
  return (
    <div>
      <Nav>
        <NavBarContainer>
          <NavLogo to="/">Divas Lift</NavLogo>
          <MobileIcon right={direction} onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLink activeClassName="navlink" to="/">
                <Trans>Home</Trans>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="navlink" to="/specifications">
                <Trans>Specifications</Trans>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="navlink" to="/design">
                <Trans>Design</Trans>
              </NavLink>
            </NavItem>{" "}
            <NavItem>
              <NavLink activeClassName="navlink" to="/luxury-design">
                <Trans>Luxury</Trans>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="navlink" to="/gallery">
                <Trans>Gallery</Trans>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeClassName="navlink" to="/contact">
                <Trans>Contact</Trans>
              </NavLink>
            </NavItem>
            <NavItem>
              <LangSwitch />
            </NavItem>
          </NavMenu>
        </NavBarContainer>
      </Nav>
    </div>
  )
}

export default NavBar
