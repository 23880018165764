import React from "react"
import { useState, useEffect } from "react"
import Select from "react-select"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import ReactCountryFlag from "react-country-flag"
import { Trans } from "react-i18next"
const LangSelector = styled.div`
  display: flex;
  cursor: pointer;
  height: 100%;
  align-items: center;
  margin-left: 15px;
`
const Language = styled.span`
  font-size: 1rem;
`
const LanguageMenu = styled.ul`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  list-style: none;
  background-color: #fff;
  position: absolute;
  box-shadow: rgb(50 50 93 / 11%) 0px 4px 6px, rgb(0 0 0 / 8%) 0px 1px 3px;
  top: 100%;
  right: ${props => (props.right ? "83%" : "0")};
  margin: 0px;
  bottom: auto;
  border-radius: 4px;
  padding: 5px 0;
  transform-origin: 50% calc(100% + 50px);
`
const LanguageOption = styled.li`
  position: relative;
  display: flex;
  padding: 0 1rem;
  align-items: center;
`
const LanguageLink = styled(Link)`
  padding: 5px 15px;
  cursor: pointer;
  width: 100%;
  color: #646369;
  text-decoration: none;
`
const LangSwitch = () => {
  const { languages, originalPath } = useI18next()
  const { language } = useI18next()
  const [isOpen, setIsOpen] = useState(false)
  const languagesLabels = [
    "English",
    "Français",
    "Español",
    "Português",
    "Polskie",
    "العربية",
  ]
  const FlagCodes = ["gb", "fr", "es", "pt", "pl", "sa"]
  const [FlagCode, setFlagCode] = useState("gb")
  const TemplanguageLabel = "label"
  const [languageLabel, setLanguageLabel] = useState(TemplanguageLabel)

  const [direction, setDirection] = useState(false)

  useEffect(() => {
    const changeDirection = () => {
      if (language == languages[0]) {
        setLanguageLabel(languagesLabels[0])
        setFlagCode("gb")
      } else if (language == languages[1]) {
        setLanguageLabel(languagesLabels[1])
        setFlagCode(FlagCodes[1])
      } else if (language == languages[2]) {
        setLanguageLabel(languagesLabels[2])
        setFlagCode(FlagCodes[2])
      } else if (language == languages[3]) {
        setLanguageLabel(languagesLabels[3])
        setFlagCode(FlagCodes[3])
      } else if (language == languages[4]) {
        setLanguageLabel(languagesLabels[4])
        setFlagCode(FlagCodes[4])
        setDirection(direction)
      } else if (language == languages[5]) {
        setLanguageLabel(languagesLabels[5])
        setFlagCode(FlagCodes[5])
      }
    }
    const changeLang = () => {
      if (language == "ar") {
        setDirection(!direction)
      } else {
        setDirection(direction)
      }
    }
    changeLang()
    changeDirection()
  }, [])
  return (
    <LangSelector onClick={() => setIsOpen(!isOpen)}>
      <Language>
        <ReactCountryFlag
          countryCode={FlagCode}
          svg
          style={{
            fontSize: "1em",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        />
        {languageLabel}
      </Language>
      <LanguageMenu isOpen={isOpen} right={direction}>
        {languages.map((lng, index) => (
          <LanguageOption key={lng}>
            <ReactCountryFlag
              countryCode={FlagCodes[index]}
              svg
              style={{
                fontSize: "1em",
                margin: "0",
              }}
            />
            <LanguageLink to={originalPath} language={lng}>
              {languagesLabels[index]}{" "}
            </LanguageLink>
          </LanguageOption>
        ))}
      </LanguageMenu>
    </LangSelector>
  )
}

export default LangSwitch
