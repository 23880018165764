import React from "react"
import { AiFillHome } from "react-icons/ai"
import { ImWrench } from "react-icons/im"
import { MdStyle } from "react-icons/md"
import { RiGalleryFill } from "react-icons/ri"
import { BiMailSend } from "react-icons/bi"
import { IoDiamond } from "react-icons/io5"
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarIconContainer,
} from "./SidebarElements"

import { Trans } from "gatsby-plugin-react-i18next"
import LangSwitch from "./LangSwitch"
const Sidebar = ({ isOpen, toggle }) => {
  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon></CloseIcon>
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            {" "}
            <SidebarLink to="/" onClick={toggle}>
              <SidebarIconContainer>
                {" "}
                <AiFillHome />
              </SidebarIconContainer>{" "}
              <Trans>Home</Trans>
            </SidebarLink>
            <SidebarLink to="/specifications" onClick={toggle}>
              <SidebarIconContainer>
                <ImWrench />
              </SidebarIconContainer>{" "}
              <Trans>Specifications</Trans>
            </SidebarLink>
            <SidebarLink to="/design" onClick={toggle}>
              <SidebarIconContainer>
                <MdStyle />
              </SidebarIconContainer>{" "}
              <Trans>Design</Trans>
            </SidebarLink>
            <SidebarLink to="/luxury-design" onClick={toggle}>
              <SidebarIconContainer>
                <IoDiamond />
              </SidebarIconContainer>{" "}
              <Trans>Luxury</Trans>
            </SidebarLink>
            <SidebarLink to="/gallery" onClick={toggle}>
              <SidebarIconContainer>
                <RiGalleryFill />
              </SidebarIconContainer>{" "}
              <Trans>Gallery</Trans>
            </SidebarLink>
            <SidebarLink to="/contact" onClick={toggle}>
              <SidebarIconContainer>
                <BiMailSend />
              </SidebarIconContainer>{" "}
              <Trans>Contact</Trans>
            </SidebarLink>
          </SidebarMenu>
        </SidebarWrapper>
      </SidebarContainer>
    </>
  )
}

export default Sidebar
