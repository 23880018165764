import styled from "styled-components"

const Section = styled.div`
  background-color: ${props => props.bgColor || "#fff"};
  background-image: url(${props => props.bgImage || "none"});
  background-repeat: no-repeat;
  background-size: contain;

  background-position-x: ${props => (props.positionX ? "left" : "right")};
  overflow: 0;
  margin-bottom: ${props => (props.spaceBottom ? "3rem" : "none")};
  min-height: ${props => props.minHeight || "85vh"};
  align-items: ${props => (props.flex ? "center" : "normal")};
  justify-content: ${props => (props.flex ? "center" : "normal")};
  display: ${props => (props.flex ? "flex" : "block")};
  flex-direction: ${props => (props.flex ? "column" : "row")};
  @media screen and (max-width: 768px) {
    color: "#000";
    background: none;
    background-color: ${props => props.bgColor || "#fff"};
  }
`

export const HeroSection = styled(Section)`
  @media screen and (max-width: 768px) {
    justify-content: start;
  }
`
export const SectionGallery = styled(Section)`
  position: relative;
`

export default Section
