import React from "react"
import { useState, useEffect } from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Trans } from "react-i18next"
import ReactCountryFlag from "react-country-flag"
const LangSelector = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  padding: 1rem;
`
const Language = styled.span`
  color: #fff;
  display: flex;
  align-items: center;
`
const LanguageMenu = styled.ul`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  list-style: none;
  background-color: #fff;
  position: absolute;
  box-shadow: rgb(50 50 93 / 11%) 0px 4px 6px, rgb(0 0 0 / 8%) 0px 1px 3px;
  bottom: 100%;
  left: ${props => (props.left ? "inherit" : "0")};
  right: ${props => (props.left ? "0" : "inherit")};
  border-radius: 4px;
  padding: 5px 0;
  margin: 0px;
  min-width: 175px;
  transform-origin: 50% calc(100% + 50px);
  ::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -6px;
    top: auto;
    left: ${props => (props.left ? "75%" : "15px")};
    margin: 0px 0px 0px -6px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border-radius: 4px 0px 0px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(82, 95, 127, 0.04) -3px -3px 5px;
    will-change: transform;
    transition-property: -webkit-transform;
  }
`
const LanguageOption = styled.li`
  position: relative;
  display: flex;
  padding: 0 1rem;
  align-items: center;
`
const LanguageLink = styled(Link)`
  padding: 5px 15px;
  cursor: pointer;
  width: 100%;
  color: #646369;
  text-decoration: none;
`
const LangSwitchFooter = () => {
  const { languages, originalPath } = useI18next()
  const { language } = useI18next()
  const [isOpen, setIsOpen] = useState(false)
  const TemplanguageLabel = "label"
  const languagesLabels = [
    "English",
    "Français",
    "Español",
    "Português",
    "Polskie",
    "العربية",
  ]
  const [languageLabel, setLanguageLabel] = useState(TemplanguageLabel)

  const FlagCodes = ["gb", "fr", "es", "pt", "pl", "sa"]
  const [FlagCode, setFlagCode] = useState("gb")

  const [direction, setDirection] = useState(false)
  useEffect(() => {
    const changeLang = () => {
      if (language == languages[0]) {
        setLanguageLabel(languagesLabels[0])
        setFlagCode("gb")
      } else if (language == languages[1]) {
        setLanguageLabel(languagesLabels[1])
        setFlagCode(FlagCodes[1])
      } else if (language == languages[2]) {
        setLanguageLabel(languagesLabels[2])
        setFlagCode(FlagCodes[2])
      } else if (language == languages[3]) {
        setLanguageLabel(languagesLabels[3])
        setFlagCode(FlagCodes[3])
      } else if (language == languages[4]) {
        setLanguageLabel(languagesLabels[4])
        setFlagCode(FlagCodes[4])
      } else if (language == languages[5]) {
        setLanguageLabel(languagesLabels[5])
        setFlagCode(FlagCodes[5])
      }
    }
    const changeDirection = () => {
      if (language == "ar") {
        setDirection(!direction)
      } else {
        setDirection(direction)
      }
    }
    changeDirection()
    changeLang()
  }, [])
  return (
    <LangSelector onClick={() => setIsOpen(!isOpen)}>
      <Language>
        <ReactCountryFlag
          countryCode={FlagCode}
          svg
          style={{
            fontSize: "1em",
            marginRight: "10px",
            marginBottom: 0,
            marginLeft: "10px",
          }}
        />
        {languageLabel}
      </Language>
      <LanguageMenu left={direction} isOpen={isOpen}>
        {languages.map((lng, index) => (
          <LanguageOption key={lng}>
            {" "}
            <ReactCountryFlag
              countryCode={FlagCodes[index]}
              svg
              style={{
                fontSize: "1em",
                margin: "0",
              }}
            />
            <LanguageLink to={originalPath} language={lng}>
              {languagesLabels[index]}
            </LanguageLink>
          </LanguageOption>
        ))}
      </LanguageMenu>
    </LangSelector>
  )
}

export default LangSwitchFooter
